import React from 'react';
import {
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  FormText,
  FormFeedback,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

export function FieldLabel({htmlFor, children, pt, ...rest}) {
  return (
    <Label htmlFor={htmlFor} {...rest}>
      {children}
    </Label>
  );
}

export function FieldInput(props) {
  let input;

  const {format, type} = props;

  if (format) {
    const {format, mask, id, name, type, ...rest} = props;

    input = (
      <NumberFormat
        className="form-control"
        type={type}
        id={id || name}
        name={name}
        format={format}
        mask={mask}
        {...rest}
      />
    );
  } else if (type === 'select') {
    const {id, name, type, options, ...rest} = props;

    input = (
      <CustomInput type={type} id={id || name} name={name} {...rest}>
        {Object.keys(options).map((option, key) => {
          return (
            <option key={key} value={option}>
              {options[option]}
            </option>
          );
        })}
      </CustomInput>
    );
  } else {
    const {name, type, ...rest} = props;

    input = <Input type={type} id={name} name={name} {...rest} />;
  }

  return input;
}

function FormField({
  row,
  label,
  description,
  name,
  placeholder,
  type,
  options,
  children,
  className,
  style,
  help,
  feedback,
  required,
  columnLabel,
  columnInput,
  hidden,
  ...rest
}) {
  const labelProps = row && !columnLabel ? {lg: 3} : columnLabel;
  const inputProps = row && !columnInput ? {lg: 9, xl: 6} : columnInput;

  return (
    <FormGroup row={row} className={className} style={style}>
      {label && (
        <FieldLabel {...labelProps} htmlFor={name}>
          <div>
            {label}
            {required && <span className="text-danger"> * </span>}
          </div>
        </FieldLabel>
      )}
      {description && <p>{description}</p>}

      {React.createElement(
        row ? Col : 'div',
        {...inputProps},
        <React.Fragment>
          {type && (
            <FieldInput
              type={type}
              name={name}
              placeholder={placeholder || label}
              options={options}
              required={required}
              className={classNames({
                'd-none': hidden,
              })}
              {...rest}
            />
          )}
          {children}
          {feedback && <FormFeedback>{feedback}</FormFeedback>}
          {help && <FormText>{help}</FormText>}
        </React.Fragment>
      )}
    </FormGroup>
  );
}

FormField.defaultProps = {
  autoComplete: 'off',
  hidden: false,
};

export default FormField;
